<template>
    <div class="execution-card-container">
        <div class="execution-card-details">
            <h1 class="order-number">Order #{{ executionAllTask.order_id }}</h1>
            <div class="details">
                <div class="order-price">
                    <h1>
                        {{
                            executionAllTask.unit_address
                                ? executionAllTask.unit_address
                                : 'Interior Design for A2 - 11001'
                        }}
                    </h1>
                    <p>
                        &#x20B9;
                        {{
                            convertToIndianNumberSystem(executionAllTask.price)
                        }}
                    </p>
                </div>
                <div class="order-expected">
                    <h1>Expected Completion</h1>
                    <p>
                        {{ executionAllTask.expected_completion_time }}
                    </p>
                </div>
            </div>
            <div class="progressbar-container">
                <h1>
                    <!-- {{
                        executionAllTask.completed
                            ? executionAllTask.completed + 'completed'
                            : '10% completed'
                    }} -->
                    10% completed
                </h1>
                <CircleProgressBar
                    style="margin-bottom: 40px;"
                    :phases="executionAllTask.phase_details"
                />
            </div>
            <!-- <div
                class="order-update"
                v-if="executionAllTask.alert_msg"
            >
                {{ executionAllTask.alert_msg }}

                <Button primary name="Normal" />
            </div> -->
        </div>
        <div class="divider"></div>
        <div class="order-progress">
            <router-link
                :to="{
                    name: 'ExecutionAllTask',
                }"
            >
                <Button normal name="Track Progress" />
            </router-link>
        </div>
    </div>
</template>

<script>
import ConvertToPrice from 'mixins/ConvertToPrice'
import Button from 'componentsv2/Button'
import CircleProgressBar from 'componentsv2/CircleProgressBar'

export default {
    name: 'OrderDetailsPage',
    props: ['executionAllTask'],
    components: {
        Button,
        CircleProgressBar,
    },
    mixins: [ConvertToPrice],
}
</script>

<style lang="scss">
@import './ExecutionCard.scss';
</style>
